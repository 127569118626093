// Import the functions you need from the SDKs you need
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './components/Landing';
import LoveList from './components/LoveList';
import GiftList from './components/GiftList';
import OptionsPage from './components/OptionsPage';
import Header from './components/Header';
import Adventure from './components/Adventure';


function App() {
  return (
    <div className="App">
    <Header />
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} exact />
          <Route path="/love-list" element={<LoveList />} />
          <Route path="/options" element={<OptionsPage />} />
          <Route path="/adventure-plans" element={<Adventure />} />
          <Route path="/gift-list" element={<GiftList />} />
        </Routes>
      </div>
    </Router>
    </div>
  );
}

export default App;

