// src/components/OptionsPage/OptionsPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OptionsPage.css'; // Make sure to create and link this CSS file
import BackButton from './BackButton';


function OptionsPage() {
    const customBackClick = (e) => {
        // Prevent default back behavior if necessary
        e.preventDefault();
        alert("No takesies-backsies!");
      };
  let navigate = useNavigate();

  const options = [
    { path: '/love-list', title: 'Just Some Things I Love About You', tagline: 'Somehow, I had to pick only 10' },
    { path: '/adventure-plans', title: 'Future Adventures', tagline: 'Somethings for us to do until next Valentine\'s Day' },
    { path: '/gift-list', title: 'Treasure Trove', tagline: 'A couple little presents for my Kimmy' }
  ];

  return (
    <div>
    <BackButton onClick={customBackClick} />
    <div className="optionsPage">
    
      {options.map((option, index) => (
        <div key={index} className="optionCard" onClick={() => navigate(option.path)}>
          <h2>{option.title}</h2>
          <p>{option.tagline}</p>
        </div>
      ))}
    </div>
    </div>
  );
}

export default OptionsPage;
