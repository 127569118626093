// src/components/LandingPage/Landing.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.css';

function Landing() {
  let navigate = useNavigate(); // Hook for navigation

  const handleYes = () => {
    navigate('/options'); // Navigate to LoveList on "Yes"
  };

  const handleNo = () => {
    // Redirect to the YouTube video on "No"
    window.location.href = 'https://www.youtube.com/watch?v=2ZIpFytCSVc';
  };

  return (
    <div className="landing">
      <h1>Do You Want to Be My Valentine?</h1>
      <div className="buttons">
        <button onClick={handleYes}>Yes</button>
        <button onClick={handleNo}>No</button>
      </div>
    </div>
  );
}

export default Landing;
