// src/components/LoveList/LoveList.js
import React from 'react';
import './LoveList.css'; // Make sure this is correctly linked
import BackButton from './BackButton'; // Ensure the correct path is used

// Utility function to convert number to ordinal words
const numberToOrdinal = (n) => {
  const ordinals = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth"];
  return ordinals[n-1] || '';
};

function LoveList() {
  const loveItems = [
    "The kindness and love that permeates your every move, you can not leave a room without adding your sparkle into it.",
    "The perseverence and dedication of a soul unfettered, determined to do what's right.",
    "The sense of adventure and spirit that keeps our lives exciting and full of wonder.",
    "The comfort and peace I feel when we're just sitting in silence.",
    "The brilliance behind everything you do, and the careful dedication to it all.",
    "The way you care deeply about everyone in your life, always being there for whoever needs you.",
    "The way you humor me and my interests, making me feel heard and seen and loved, no matter how silly.",
    "The power you hold, when people rely on you, you make every effort to make sure that they are happy.",
    "Your sense of humor that matches mine, making every part of my life feel as supported and appreciated as one could hope.",
    "The little acts of love you do every day, from a warm hug to a quick check-in text, that make me feel so valued and cherished."
  ];

  return (
    <div className="loveList">
      <BackButton />
      {loveItems.map((item, i) => (
        <section key={i} className="loveItem">
          <h2>The {numberToOrdinal(i + 1)} Thing I Love About You (in no particular order) </h2>
          <p>{item}</p>
        </section>
      ))}
    </div>
  );
}

export default LoveList;
