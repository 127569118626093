// src/components/AdventurePlans/AdventurePlans.js
import React from 'react';
import BackButton from './BackButton';
import './Adventure.css'; // Make sure to create and link this CSS file

function Adventure() {
  return (
    <div className="adventurePlans">
    <BackButton />
      <h2>Our Post-Graduation Adventure Bucket List</h2>
      <div className="plan">
        <h3>🌍 Places and Things I Want to Do With You 💕 </h3>
        <ul>
          <li>Wander through the cherry blossoms in Kyoto, Japan during spring.</li>
          <li>Experience the northern lights and natural beauty of Iceland.</li>
          <li>Learn to make authentic pizza and pasta in a cooking class in Italy.</li>
          <li>Go on a safari adventure in the Serengeti National Park, Tanzania.</li>
        </ul>
      </div>
      <div className="plan">
        <h3>🎓 Celebrate Our Achievements</h3>
        <ul>
          <li>Plan a weekend getaway to relax and unwind after your final exams.</li>
          <li>Create a time capsule of our college years to open in ten years.</li>
        </ul>
      </div>
      <div className="plan">
        <h3>🐾 Welcome a New Family Member</h3>
        <ul>
          <li>I think Zaytoon needs a sister, and you're the best choice to pick one for us. </li>
        </ul>
      </div>
    </div>
  );
}

export default Adventure;
