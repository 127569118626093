// src/components/GiftList/GiftList.js
import React from 'react';
import './GiftList.css'; // Make sure to create and link this CSS file
import BackButton from './BackButton'; // Adjust path as needed
import smileNecklaceImage from '../gift.png';
import purseImage from "../gift2.png"


function GiftList() {
  const gifts = [
    { name: "Custom Playlist", description: "I think this one should be heard in person, can't ruin it" },
    { name: "Mystery Box", description: "Shh..., it's a surprise. No pictures" },
    { name: "A Cute Purse", description: "Because you've been looking for something to hold your phone, to free your hands to pet cats", picture: purseImage},
    { name: "Tiffany's Smile Necklace", description: "A symbol of joy and beauty, as radiant as your smile, Kimmy.", picture: smileNecklaceImage  }
  ];

  return (
    <div className="giftList">
      <BackButton />
      Here are some of the things that I was going to ship, but couldn't, but you will see them in two short weeks:
      <br></br><br></br><br></br>
      {gifts.map((gift, index) => (
        <div key={index} className="giftItem">
        <h2>{gift.name}</h2>
        <p>{gift.description}</p>
        <img src={gift.picture} className="giftImage" />
        </div>)
      )}
    </div>
  );
}

export default GiftList;
