// src/components/BackButton/BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.css';

function BackButton({ onClick }) {
  let navigate = useNavigate();

  const defaultClickHandler = () => {
    navigate(-1);
  };

  return (
    <button className="backButton" onClick={onClick || defaultClickHandler}>
    Back
    </button>
  );
}

export default BackButton;
