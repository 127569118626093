// src/components/Header/Header.js
import React from 'react';
import './Header.css'; // Make sure to create and link this CSS file

function Header() {
  return (
    <header className="specialHeader">
      <h1>For my very special Kimmy, my Valentine 💕 </h1>
    </header>
  );
}

export default Header;
